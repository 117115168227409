import {computed, inject, Injectable, signal} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {take} from 'rxjs';
import {Customer, CustomerDetail, CustomerState, PageDto} from '@epg-apps/cdh-models';
import {environment} from '@epg-apps/cdh-environments';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private static readonly CUSTOMER_CDH_API = environment.cdhApi + 'customers';
  http = inject(HttpClient);

  private customerState = signal<CustomerState>({
    customers: {
      pageable: {
        pageSize: 10,
        pageNumber: 0,
        first: true,
        last: false,
        totalElements: 0,
        totalPages: 1
      },
      content: []
    },
    isLoadingPage: false,
    selectedCustomer: undefined
  });

  customers = computed(() => this.customerState().customers.content);
  selectedCustomer = computed(() => this.customerState().selectedCustomer);
  isLoadingPage = computed(() => this.customerState().isLoadingPage);
  mostRecentPageable = computed(() => this.customerState().customers.pageable);

  public loadCustomersPage(query: string = '', pageNumber: number = 0, pageSize: number = 10) {
    this.getCustomersPage(query, pageNumber, pageSize).pipe(take(1)).subscribe(customersPage => {
      this.setCustomersPage(customersPage);
    })
  }

  public getCustomersPage(query: string, pageNumber: number, pageSize: number) {
    const headerOptions = {
      params: {
        query: query,
        pageNumber: pageNumber,
        pageSize: pageSize
      }
    };

    this.setIsLoading();
    return this.http.get<PageDto<Customer>>(CustomerService.CUSTOMER_CDH_API + 'Page', headerOptions);
  }

  public setCustomersPage(customerPage: PageDto<Customer>) {
    this.customerState.update((state): CustomerState => {
      return {
        ...state,
        isLoadingPage: false,
        customers: customerPage
      };
    });
  }

  public loadCustomerDetails(id: string) {
    this.getCustomerDetails(id).subscribe(customerDetailData => {
      this.setCustomerDetails(customerDetailData);
    })
  }

  public getCustomerDetails(id: string) {
    const customersAPi = CustomerService.CUSTOMER_CDH_API + '/' + id;
    return this.http.get<CustomerDetail>(customersAPi)
  }

  public setCustomerDetails(customerDetail: CustomerDetail) {
    this.customerState.update(state => ({
      ...state,
      selectedCustomer: customerDetail
    }))
  }

  private setIsLoading() {
    this.customerState.update(state => {
      return {
        ...state,
        isLoadingPage: true
      };
    });
  }
}
