import { computed, inject, Injectable, signal } from '@angular/core';

import { v7 as uuidv7 } from 'uuid';
// import { HttpClient, HttpEventType } from '@angular/common/http';

export interface DownloadItem  {
  id: string;
  fileName: string;
  url: string;
  progress: number;
  status: 'pending' | 'downloading' | 'completed' | 'failed';
  error?: string;
}

export interface DownloadState {
  downloading: boolean;
  progress: number;
  error?: string | null;
  isCompleted?: boolean;
}



// Define a type for the callback function
export type DownloadCallback = (
  id: string,
  progress: number,
  status: DownloadItem['status'],
  error?: string
) => void;

@Injectable({
  providedIn: 'root'
})
export class EpgDownloadCenterService {
  private downloads = signal<DownloadItem[]>([]);
  // private httpClient = inject(HttpClient);

  //--- SingleFile Donwload ---- //
  private singleFileDownloadState = signal<DownloadState>({
    downloading: false,
    progress: 0,
    error: null,
    isCompleted: false
  });

  public readonly downloadState = this.singleFileDownloadState;
  public readonly isDownloading = computed(() => this.downloadState().downloading);
  public readonly downloadCompleted = computed(() => this.downloadState().isCompleted);

  // ---!SingleFile Donwload----//

  public readonly downloadList = this.downloads.asReadonly;
  public readonly activeDownloadsCount = computed( () => this.downloads.length);

  /**
   * Adds a new download to the center.
   * @param fileName The name of the file being downloaded.
   * @param url The URL of the file to download.
   * @returns The ID of the newly added download.
   */
  addDownload(fileName: string, url: string): string {
    const id = uuidv7();
    const newDownload: DownloadItem = {
      id,
      fileName,
      url,
      progress: 0,
      status: 'pending'
    };

    this.downloads.update((download) => [...download, newDownload]);
    return id;
  }

  startDownload(id: string): void {
    const download = this.getDownloadById(id);
    if(!download) {
      console.error(`Download with ID ${id} not found.`);
      return;
    }

    this.updateDownloadStatus(id, 'downloading');

  }

  download(id: string,url: string, filename: string) {

    const downloadItem= this.getDownloadById(id);

    // TODO: Complete download progress

    // return this.httpClient.get(url, {
    //   reportProgress: true,
    //   observe: 'events',
    //   responseType: 'blob'
    // }).pipe(
    // ).subscribe( event => {
    //   switch(event.type) {
    //     case HttpEventType.DownloadProgress:
    //       this.updateDownloadStatus(id, 'pending');
    //       this.updateDownloadProgress(id, downloadItem.progress);
    //       break;
    //     case HttpEventType.Response:
    //       this.updateDownloadStatus(id, 'completed');
    //       break;
    //   }
    // })
  }

  /**
   * Gets a specific download item by its ID.
   * @param id The ID of the download item to retrieve.
   * @returns The download item if found, otherwise undefined.
   */
  getDownloadById(id: string): DownloadItem | undefined {
    return this.downloads().find((d) => d.id === id);
  }

  /**
   * Updates the progress of a specific download item.
   * @param id The ID of the download item to update.
   * @param progress The new progress value (0-100).
   */
  private updateDownloadProgress(id: string, progress: number): void {
    this.downloads.update((downloads) =>
      downloads.map((d) => (d.id === id ? { ...d, progress } : d))
    );
  }

  /**
   * Updates the status of a specific download item.
   * @param id The ID of the download item to update.
   * @param status The new status ('pending', 'downloading', 'completed', 'failed').
   * @param error Optional error message.
   */
  private updateDownloadStatus(id: string, status: DownloadItem['status'], error?: string): void {
    this.downloads.update((downloads) =>
      downloads.map((d) => (d.id === id ? { ...d, status, error } : d))
    );
  }
}
