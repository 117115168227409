import {CDHAppEnvironment} from "./environment.types";

export const environment = {production: false,
  version: CDHAppEnvironment.ACC,
  applicationType: "APPS",
  employeeApi: 'https://acc-epemployee.easypay-group.com/api/',
  cdhApi: 'https://acc-apim.easypay-group.com/external/cdh/api/',
  iamApi: 'https://acc-apim.easypay-group.com/external/iam/api/',
  defaultTimezone: 'Europe/Brussels',
  baseUrl: "https://acc-apps.easypay-group.com",
  authenticationUrl: "https://acc-portal.easypay-group.com/login",
  logoutUrl: "https://acc-portal.easypay-group.com/logout",
  bypassSecurity: false,
  apimSubscriptionKey: "8619a55459624c58b028beb62fc228cb"
};
